import firebase from 'firebase'

//get rooms
export const getEditionData = async (edition) => {
    const db = firebase.firestore()
    try {
        console.log(edition)
        const editionDoc = await db.collection('festival').doc(edition).get()
        console.log(editionDoc)
        const editionData = await editionDoc.data()
        return editionData
    } catch (e) {
        console.error(e, 'in getroomdata')
        return 'ERROR'
    }
}

export const closeEdition = async (edition) => {
    const db = firebase.firestore()
    try {
        console.log(edition)
        let token = await firebase.auth().currentUser.getIdToken()
        let body = {edition, uid: firebase.auth().currentUser.uid}
        let request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        }

        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/closeEdition`, request)
        return await data.json()
        ///returns permissions/if you are an admin or not.
    } catch (e) {
        console.error(e, 'in getroomdata')
        return 'ERROR'
    }
}
export const getEditionDataAuth = async (edition) => {
    const db = firebase.firestore()
    try {
        console.log(edition)
        let token = await firebase.auth().currentUser.getIdToken()
        let body = {}
        let request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        }

        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/commonDash/getEditionDataAuth`, request)
        return await data.json()
        ///returns permissions/if you are an admin or not.
    } catch (e) {
        console.error(e, 'in getroomdata')
        return 'ERROR'
    }
}
export const createRoomAuth = async (edition, roomKey, roomData) => {
    const db = firebase.firestore()
    try {
        //console.log(edition)
        let token = await firebase.auth().currentUser.getIdToken()
        let body = { edition, roomKey, roomData }
        let request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        }
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/commonDash/createRoomAuth`, request)
        return await data.json()
        ///returns permissions/if you are an admin or not.
    } catch (e) {
        console.error(e, 'in getroomdata')
        return 'ERROR'
    }
}
//edit room
export const editRoomAuth = async (edition, roomKey, roomData) => {
    const db = firebase.firestore()
    try {
        //console.log(edition)
        let token = await firebase.auth().currentUser.getIdToken()
        let body = { edition, roomKey, roomData }
        let request = {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        }
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/commonDash/editRoomAuth`, request)
        return await data.json()
        ///returns permissions/if you are an admin or not.
    } catch (e) {
        console.error(e, 'in editroom')
        return 'ERROR'
    }
}

//add room
export const createRoom = async (edition, roomKey, roomData) => {
    const db = firebase.firestore()
    try {
        let data = await db.collection('festival').doc(edition).get()
        let oldRooms = data.data() ? (data.data().rooms ? data.data().rooms : []) : []
        let newRooms = [...oldRooms, roomData.key]
        let oldOrganizers = data.data() ? (data.data().organizers ? data.data().organizers : {}) : {}
        let newOrganizers = { ...oldOrganizers, [roomData.key]: { ...roomData } }
        await db.collection('festival').doc(edition).set(
            {
                rooms: newRooms,
                organizers: newOrganizers,
            },
            { merge: true },
        )
    } catch (e) {
        console.error(e, 'in createroom')
        return 'ERROR'
    }
}

//delete room
export const deleteRoom = async (edition, roomKey) => {
    const db = firebase.firestore()
    try {
        let data = await db.collection('festival').doc(edition).get()
        let oldRooms = data.data().rooms
        console.log(oldRooms)
        let newRooms = [...oldRooms].reduce((accumulator, currentVal) => {
            if (currentVal !== roomKey) {
                accumulator.push(currentVal)
            }
            return accumulator
        }, [])
        console.log(newRooms)
        let oldOrganizers = data.data().organizers
        let newOrganizers = Object.keys(oldOrganizers).reduce((object, key) => {
            if (key !== roomKey) {
                object[key] = oldOrganizers[key]
            }
            return object
        }, {})
        await db.collection('festival').doc(edition).update({
            rooms: newRooms,
            organizers: newOrganizers,
        })
    } catch (e) {
        console.error(e, 'in deleteroom')
        return 'ERROR'
    }
}

//edit room
export const editRoom = async (edition, roomKey, roomData) => {
    const db = firebase.firestore()
    try {
        console.log(edition, roomKey, roomData)
        let data = await db.collection('festival').doc(edition).get()
        let oldOrganizers = data.data().organizers
        let oldRoom = oldOrganizers[roomData.key]
        let newOrganizers = { ...oldOrganizers, [roomData.key]: { ...oldRoom, ...roomData } }
        await db.collection('festival').doc(edition).update({
            organizers: newOrganizers,
        })
    } catch (e) {
        console.error(e, 'in editroom')
        return 'ERROR'
    }
}

export const setEditionCollective = async (edition, collectiveID) => {
    const db = firebase.firestore()
    let data = await db.collection('festival').doc(edition).update({
        collective: collectiveID,
    })
}

export const createCommonCollective = async ({ name, blogs }) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        blogID: '',
        name,
        common: true,
        blogs: [...new Set(blogs)],
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/collective/createCollective`, request)
        return await data.json()
    } catch (e) {
        console.error(e)
    }
}

export const updateCollective = async ({ collectiveID, blogs }) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        collectiveID,
        blogs: [...new Set(blogs)],
        common: true,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/collective/updateMembers`, request)
        return await data.json()
    } catch (e) {
        console.error(e)
    }
}
