import React, { useState } from 'react';
import firebase from 'firebase';
import { uploadImage } from './uploadHelpers';

const UploadFile = ({setFileURL}) => {
    const [loading, setLoading] = useState(false)

    return <div style={{display: 'flex', flexDirection: 'row'}}>
        <input
            type="file"
            id="blogProfileElem"
            accept="image/*"
            single
            style={{display: 'none'}}
            onChange={async (e) => {
                let files = e.target.files
                if (files.length > 0) {
                    setLoading(true)
                    let url = await uploadImage(files[0], firebase.auth().currentUser, (v) => {setLoading(v)})
                    console.log(url)
                    setFileURL(url)
                    setLoading(false)
                }
            }}
        />
        {loading ? <div>UPLOADING IMAGE... {loading}%</div> :
        <button >
                            <label style={{ margin: '-8px -9px', padding: '8px 9px' }} htmlFor="blogProfileElem">
                                UPLOAD IMAGE
                            </label>
                        </button>
        }
    </div>
}

export default UploadFile
