import React, { useState, useEffect } from 'react'
import Room from './Room'
import RoomForm from './RoomForm'
import { createCommonCollective, getEditionData, setEditionCollective, updateCollective,closeEdition } from '../helpers/editionData'
import { getShows } from '../helpers/shows'

//const edition = 'test'

const roomContainerStyle = {
    display: 'flex',
    flexDirection: 'horizontal',
    flexWrap: 'nowrap',
    overflowX: 'scroll',
    maxWidth: '100vw',
    margin: 16,
}

const dashboardButtonStyle = {
    padding: 16,
}

const dashboardTitleStyle = {
    fontSize: 24,
    fontWeight: 300,
    marginBottom: 16,
}

function Dashboard() {
    const [edition, setEdition] = useState(false)
    const [editionText, setEditionText] = useState('')
    const [editionData, setEditionData] = useState(false)
    const [shows, setShows] = useState(false)

    const handleGetShows = async (edition) => {
        const data = await getShows(edition)
        if (data !== 'ERROR') {
            setShows(data)
        } else {
            console.log('error in the get edition')
        }
    }
    const getEdition = async (edition) => {
        const data = await getEditionData(edition)
        if (data !== 'ERROR') {
            console.log(data)
            setEditionData(data)
        } else {
            console.log('error in the get edition')
        }
    }

    useEffect(() => {
        if (edition) {
            getEdition(edition)
            handleGetShows(edition)
        }
    }, [edition])

    //console.log(shows)

    return (
        <div className="Dashboard" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <div style={{ width: 240, height: '100vh', textAlign: 'left', borderRight: '1px solid white' }}>
                <div className="DashBoardButton" style={dashboardButtonStyle}>
                    <div className="dashboardTitle" style={dashboardTitleStyle}>
                        COMMON EDITION: {edition}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <input
                            value={editionText}
                            type="text"
                            placeholder="edition code"
                            style={{ flex: 1, maxWidth: 'calc(100% - 96px)' }}
                            onChange={(e) => setEditionText(e.target.value)}
                        />
                        <button onClick={() => setEdition(editionText)}>SUBMIT</button>
                    </div>
                    {edition && (
                        <>
                            <div>
                                START TIME:{' '}
                                {edition && editionData && editionData.start && new Date(editionData.start).toString()}{' '}
                                <i>in your local time zone</i>
                            </div>
                            <div>
                                END TIME:{' '}
                                {edition && editionData && editionData.end && new Date(editionData.end).toString()}{' '}
                                <i>in your local time zone</i>
                            </div>
                            {editionData && editionData.rooms ? (
                                <RoomForm
                                    edition={edition}
                                    getEdition={getEdition}
                                    roomsArr={editionData.rooms}
                                    formType="newRoom"
                                />
                            ) : (
                                <RoomForm edition={edition} getEdition={getEdition} formType="newRoom" />
                            )}
                            <div>
                                <button onClick={() => {
                                    closeEdition(edition)
                                }}>
                                    CLOSE COMMON
                                </button>
                            </div>
                        </>
                    )}
                    {edition && editionData && !editionData.collective && (
                        <button
                            onClick={async () => {
                                const roomAdmins = editionData.rooms
                                    .map((room, index) => {
                                        if (editionData.organizers[room] && editionData.organizers[room].adminID)
                                            return editionData.organizers[room].adminID
                                    })
                                    .filter((id) => id)
                                const data = {
                                    name: edition.toUpperCase(),
                                    blogs: [
                                        ...shows.filter((s) => s.currentsID).map((s) => s.currentsID),
                                        ...roomAdmins,
                                    ],
                                }
                                let response = await createCommonCollective(data)
                                if (response && response.id) {
                                    await setEditionCollective(edition, response.id)
                                }
                            }}
                        >
                            CREATE COMMON COLLECTIVE
                        </button>
                    )}
                    {edition && editionData && editionData.collective && (
                        <button
                            onClick={async () => {
                                const roomAdmins = editionData.rooms
                                    .map((room, index) => {
                                        if (editionData.organizers[room] && editionData.organizers[room].adminID)
                                            return editionData.organizers[room].adminID
                                    })
                                    .filter((id) => id)
                                const data = {
                                    collectiveID: editionData.collective,
                                    blogs: [
                                        ...shows.filter((s) => s.currentsID).map((s) => s.currentsID),
                                        ...roomAdmins,
                                    ],
                                }
                                let response = await updateCollective(data)
                            }}
                        >
                            UPDATE COMMON COLLECTIVE
                        </button>
                    )}
                </div>
            </div>
            <div style={{ width: 'calc(100% - 240px)' }}>
                {edition && editionData && (
                    <>
                        <div className="RoomContainer" style={roomContainerStyle}>
                            {editionData && editionData.organizers && shows
                                ? editionData.rooms.map((room, index) => (
                                      <Room
                                          edition={edition}
                                          roomData={editionData.organizers[room]}
                                          rooms={editionData.rooms}
                                          roomKey={index}
                                          getEdition={getEdition}
                                          handleGetShows={handleGetShows}
                                          shows={shows}
                                          key={index}
                                      />
                                  ))
                                : null}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default Dashboard
