import React from 'react'
// import { values } from 'lodash';
import DateTimePicker from 'react-datetime-picker'
function TextInput(props) {
    const { func, type, value, text, initialVal, isRoom, placeholder, name } = props
    if (value) {
        return (
            <div className="ShowInput inputHolder">
                <div className="fieldLabel">{text}</div>
                <input
                    placeholder={placeholder}
                    name={name}
                    type="text"
                    text={initialVal}
                    value={value}
                    onChange={(event) => {
                        func(event.target.value)
                    }}
                    required
                />
            </div>
        )
    } else {
        return (
            <div className="RoomInput inputHolder">
                <div className="fieldLabel">{text}</div>
                <input
                    placeholder={placeholder}
                    name={name}
                    type="text"
                    text={initialVal}
                    value={value}
                    onChange={func}
                    required
                />
            </div>
        )
    }
}
function TextareaInput(props) {
    const { func, type, value, text, initialVal, isRoom, placeholder, name } = props

    return (
        <div className="ShowInput">
            <div className="fieldLabel">{text}</div>
            <textarea
                placeholder={placeholder}
                name={name}
                type="text"
                text={initialVal}
                value={value}
                onChange={(event) => {
                    func(event.target.value)
                }}
                required
            />
        </div>
    )
}
function NumberInput(props) {
    const { func, type, value, text, initialVal, isRoom, placeholder, name } = props

    return (
        <div className="ShowInput inputHolder">
            <div className="fieldLabel">{text}</div>
            <input
                placeholder={placeholder}
                style={{ maxWidth: 96 }}
                name={name}
                type="number"
                text={initialVal}
                value={value}
                onChange={(event) => {
                    func(event.target.value)
                }}
                required
            />
        </div>
    )
}

function EmptyTextInputShow(props) {
    const { text, func, type, name, value, cols, rows, placeholder } = props
    return (
        <div className="EmptyTextInputShow">
            <div className="fieldLabel">{text}</div>
            <input
                placeholder={placeholder}
                type="text"
                name={name}
                value={value}
                onChange={(event) => {
                    func(event.target.value)
                }}
                rows={rows}
                cols={cols}
                required
            />
        </div>
    )
}
function EmptyTextInputRoom(props) {
    const { text, func, type, name, value, cols, rows, placeholder } = props
    return (
        <div className="EmptyTextInputRoom">
            <div className="fieldLabel">{text}</div>
            <input
                placeholder={placeholder}
                type="text"
                name={name}
                value={value}
                onChange={(event) => {
                    func(event)
                }}
                rows={rows}
                cols={cols}
                required
            />
        </div>
    )
}

function TimeInputRoom(props) {
    const { text, func, type, value, name, formType, isTime } = props
    return (
        <div className="TimeInputRoom">
            <div className="fieldLabel">{text}</div>
            <DateTimePicker
                onChange={(event) => {
                    func(event.target.value)
                }}
                value={value}
            />

            <i>in your local time zone</i>
        </div>
    )
}

function TimeInputShow(props) {
    const { text, func, type, value, name, formType, isTime, disabled } = props
    if (disabled)
        return (
            <div className="TimeInputShow">
                <div className="fieldLabel">{text}</div>
                <div style={{ fontSize: 10 }}>{value.toString()}</div>
                <i style={{ fontSize: 10 }}>in your local time zone</i>
            </div>
        )
    return (
        <div className="TimeInputShow">
            <div className="fieldLabel">{text}</div>
            <DateTimePicker
                onChange={(d) => {
                    func(d)
                }}
                value={value}
            />
            <i style={{ fontSize: 10 }}>in your local time zone</i>
        </div>
    )
}

function DurationInputShow(props) {
    const { text, func, type, value, name, formType, isTime, initialVal, placeholder } = props
    let timeval = value ? parseInt(value) : 0
    const minutes = Math.floor(timeval / 60)
    const sec = timeval - minutes * 60

    return (
        <div className="TimeInputShow">
            <div className="fieldLabel">{text}</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <input
                    placeholder="minutes"
                    style={{ maxWidth: 64 }}
                    name={name + 'min'}
                    type="number"
                    value={minutes}
                    onChange={(event) => {
                        const newMin = event.target.value ? event.target.value : 0
                        func(parseInt(newMin) * 60 + sec)
                    }}
                    required
                />
                <div>MIN</div>
                <input
                    placeholder="seconds"
                    style={{ maxWidth: 64 }}
                    name={name + 'sec'}
                    type="number"
                    value={sec}
                    onChange={(event) => {
                        const newSec = event.target.value ? event.target.value : 0
                        func(parseInt(newSec) + minutes * 60)
                    }}
                    required
                />
                <div>SEC</div>
            </div>
        </div>
    )
}

function ImageInput(props) {
    const { text, func, type, value, name } = props
    return (
        <div className="FileInput inputHolder">
            <div className="fieldLabel">{text}</div>
            <input
                type="file"
                name={name}
                onChange={(event) => {
                    func(event.target.files[0])
                }}
                required
            />
        </div>
    )
}

function InputComponent(props) {
    const { func, formType, placeholder, value, roomData, text, isTime, isImage, name, type, disabled } = props

    if (type === 'image' || isImage === 'true') {
        return <ImageInput func={func} name={name} />
    } else if (type === 'showTime' || isTime === 'showTime') {
        return (
            <TimeInputShow
                isTime={isTime}
                func={func}
                value={value}
                text={text}
                name={name}
                formType={formType}
                disabled={disabled}
            />
        )
    } else if (type === 'number') {
        return (
            <NumberInput
                placeholder={placeholder}
                isTime={isTime}
                func={func}
                value={value}
                text={text}
                name={name}
                formType={formType}
            />
        )
    } else if (type === 'duration') {
        return (
            <DurationInputShow
                placeholder={placeholder}
                isTime={isTime}
                func={func}
                value={value}
                text={text}
                name={name}
                formType={formType}
            />
        )
    } else if (type === 'roomTime' || isTime === 'roomTime') {
        return <TimeInputRoom isTime={isTime} func={func} text={text} name={name} formType={formType} />
    } else if (type === 'area') {
        return (
            <TextareaInput
                placeholder={placeholder}
                formType={formType}
                initialVal={value}
                text={text}
                func={func}
                value={value}
                name={name}
            />
        )
    } else if (formType === 'newShow') {
        return <EmptyTextInputShow placeholder={placeholder} func={func} text={text} value={value} name={name} />
    } else if (formType === 'editShow') {
        return (
            <TextInput
                placeholder={placeholder}
                formType={formType}
                initialVal={value}
                text={text}
                func={func}
                value={value}
                name={name}
            />
        )
    } else if (formType === 'newRoom') {
        return <EmptyTextInputRoom placeholder={placeholder} func={func} text={text} value={value} name={name} />
    } else if (formType === 'editRoom') {
        let val = value
        val = roomData[name]
        return (
            <TextInput
                placeholder={placeholder}
                initialVal={val}
                text={text}
                func={func}
                value={value}
                name={name}
                formType={formType}
            />
        )
    } else {
        return <div>hello</div>
    }
}

export default InputComponent
