import React, { useState } from 'react'
import { moveShow } from '../helpers/shows'
import ShowForm from './ShowForm'

const showStyle = {
    margin: 8,
    padding: 8,
    border: '1px solid rgba(255,255,255,.33)',

    maxWidth: '320px',
}

const showLeftStyle = {
    fontSize: 10,
    textAlign: 'left',
    height: '100%',
}

const showMiddleStyle = {
    textAlign: 'left',
}
const showRightStyle = {
    textAlign: 'right',
    alignItems: 'center',
}

const imageDivStyle = {
    maxHeight: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
}
const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
}

function ShowItem(props) {
    var subtitle
    const { showData, handleGetShows, getEdition, permission, edition } = props

    return (
        <div className="Show" style={showStyle}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                <img
                    src={showData.image}
                    style={{ width: 48, minWidth: 48, height: 200, marginRight: 8, objectFit: 'cover' }}
                />
                <div>
                    {showData && showData.title && (
                        <div>
                            title: <i>{showData.title}</i>
                        </div>
                    )}
                    <div style={{ marginBottom: 16 }}>
                        artist: <b>{showData.artist}</b>
                    </div>

                    <div className="ShowLeft" style={showLeftStyle}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: 48 }}>START</div>
                            <div style={{ fontStyle: 'italic' }}>
                                {showData.startObj
                                    ? new Date(showData.startObj.seconds * 1000).toString()
                                    : showData.startTime.toString()}
                            </div>
                        </div>

                        <div>
                            duration: <b>{showData.duration}</b>
                        </div>
                        <div>
                            location:{' '}
                            <b>
                                {showData.lat}, {showData.lon}
                            </b>
                        </div>
                        {showData.currentsID && (
                            <div>
                                currentsID: <b>{showData.currentsID}</b>
                            </div>
                        )}
                        <div style={{ maxHeight: 24, overflow: 'hidden' }}>bio: {showData.description}</div>
                        {showData.email && (
                            <div>
                                email: <b>{showData.email}</b>
                            </div>
                        )}
                        <div>
                            streamLink: <b>{showData.link}</b>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: 48 }}>END</div>
                            <div style={{ fontStyle: 'italic' }}>
                                {showData.endObj
                                    ? new Date(showData.endObj.seconds * 1000).toString()
                                    : showData.endTime.toString()}
                            </div>
                        </div>
                        {permission && (
                            <ShowForm
                                show={showData}
                                edition={edition}
                                formType="editShow"
                                handleGetShows={handleGetShows}
                                getEdition={getEdition}
                            />
                        )}
                        {true && (
                            <button
                                onClick={async () => {
                                    console.log(showData)
                                    await moveShow(edition, showData.id)
                                }}
                            >
                                MOVE SHOW
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <div className="ShowRight" style={showRightStyle}></div>
        </div>
    )
}

export default ShowItem
