import firebase from 'firebase'
import fetch from 'node-fetch'

//get shows
export const getShows = async (edition) => {
    const db = firebase.firestore()
    try {
        const showDocs = await db.collection('festival').doc(edition).collection('shows').get()
        //const uDocs = await db.collection('festival').doc(edition).collection('users').get()
        //const showus = uDocs.docs.map((doc) => {return doc.data().email})
        //console.log(showus)
        const shows = showDocs.docs.map((doc) => {
            return { ...doc.data(), id: doc.id }
        })
        console.log(shows)
        return shows
        // let showsByRoom = {};
        // shows.map((show) => {
        //   if(showsByRoom[show.room]) {
        //     let roomArr = showsByRoom[show.room];
        //     showsByRoom[show.room] = [...roomArr, show]
        //   } else {
        //     showsByRoom[show.room] = [show]
        //   }
        //   return showsByRoom
        // })
        // Object.keys(showsByRoom).forEach(roomId => {
        //   let arr = showsByRoom[roomId]
        //   arr.sort((a, b) => {
        //     return b.startTime - a.startTime
        //   })
        //   showsByRoom[roomId] = arr;
        // })
        // return showsByRoom;
    } catch (e) {
        console.error(e)
    }
}
//create show in test
// export const createShow = async (edition, show) => {
//   const db = firebase.firestore()
// try {
//   const showDocs = await db.collection('festival').doc(edition).collection('shows').add(show)
//   console.log(showDocs)
// } catch(e) {
//   console.error(e, 'in create show')
//   return 'ERROR'
// }
// }

//create show in production
export const createShowAuth = async (edition, show) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        data: show,
        festid: edition,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/commonDash/submitShowAuth`, request)
        console.log(data)
        return data
    } catch (e) {
        console.error(e, 'in create show')
        return 'ERROR'
    }
}
//create show in production
export const createShow = async (edition, show) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        data: show,
        festid: edition,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/submitApprovedCommonStream`, request)
        console.log(data)
        return data
    } catch (e) {
        console.error(e, 'in create show')
        return 'ERROR'
    }
}

//delete show in test
// export const deleteShow = async (edition, showId) => {
//   const db = firebase.firestore()
// try {
//   const res = await db.collection('festival').doc(edition).collection('shows').doc(showId).delete()
//   return res;
// } catch(e) {
//   console.error(e, 'in createroom')
//   return 'ERROR'
// }
//}

//delete show in prod
export const deleteShow = async (edition, showId) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        festid: edition,
        id: showId,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/deleteShow`, request)
        console.log(data)
        return data
    } catch (e) {
        console.error(e, 'in create show')
        return 'ERROR'
    }
}

export const moveShow = async (edition, showId) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        festid: edition,
        id: showId,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        const data = await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/moveShow`, request)
        console.log(data)
        return data
    } catch (e) {
        console.error(e, 'in create show')
        return 'ERROR'
    }
}
// edit show in test
// export const editShow = async (edition, show, id) => {
//   const db = firebase.firestore()
// try {
//   console.log(show, ' this is in eidt show')
//   let data = await db.collection('festival').doc(edition).collection('shows').doc(id).get();
//   let oldShowInfo = data.data()
//   console.log('this is the data.data()', oldShowInfo)
//   let newShow = {...oldShowInfo, ...show}
//   await db.collection('festival').doc(edition).collection('shows').doc(id).update(newShow)
// } catch(e) {
//   console.error(e, 'in edot room')
//   return 'ERROR'
// }
// }

// edit show in production
// delete old show, add new one. cant write to it
export const editShow = async (edition, show, showId) => {
    let token = await firebase.auth().currentUser.getIdToken()
    let body = {
        festid: edition,
        id: showId,
        data: show,
    }
    let request = {
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(body),
    }
    try {
        let editedShow = await fetch(`${process.env.REACT_APP_BACKEND_URL}/fest/submitEditedCommonStream`, request)
        console.log(await editedShow.json())
        return editedShow
    } catch (e) {
        console.error(e, 'in create show')
        return 'ERROR'
    }
}

//DRAG AND DROP / CHANGE TIMES
//order is start time
//ordered
//item splice out, and splice in - shifting order
//call calulate start
// for loop through array (room's start time, first items length to get 2nd items start time)
//store in database (update)

//moving show up by one
// from index and to index = math.min()
//min in for loop
// //this will cut down to only necessary updates
